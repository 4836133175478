
.tab {
  padding:0 !important;
   background-color: rgba(0,0,0,0.3);
   display: node !important;
  /*
   color: rgba(255,255,255,0.3);
  pointer-events: none;     */
}
.nav-tabs .nav-link{
  border:none !important;
  border-radius: 0 !important;
  /* border-top-left-radius: 0 !important;
  border-top-left-radius: 0 !important; */
}

/* .tab {
  padding:0 !important;
  background-color: rgba(0,0,0,0.3);
  color: rgba(255,255,255,0.3);
  pointer-events: none;  
  border-bottom:none !important; 
} */

