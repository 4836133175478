.header{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.logo {
  width: 180px;  
  height: 80px;
  margin-left: 3rem;
  /* style={{ height: '70px', width: '180px' }} */
}

.iconoscadem{
  margin-right: 3rem;
  display: flex;  
  align-items: center;
  flex-wrap: nowrap;
}

.formClass{
  /* z-index: 1; */
  padding-bottom: 30px;
}

.form-group{
  margin-bottom: 0.6rem !important;
}
.title_container{
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boton{
  background-color: #7c3088 ;
  margin-left: 10px ;
  color: #FFF ;  
}

.nav-tabs{
border-bottom: none !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{  
  
  color: #fff !important;
  /* 5348E2 */
  background-image: linear-gradient(to left, #5348E2, #80DEEA) !important;  
  /* border-color: #7c3088 !important */
  border:none !important;     
}



.MuiInputBase-input {
  color:#FFF;
}


.MuiFormLabel-root{
  color: white !important;
}

.MuiInput-underline-24:hover:not(.MuiInput-disabled-23):not(.MuiInput-focused-22):not(.MuiInput-error-25):before {
  border-bottom: 2px solid rgb(255, 255, 255) !important;
}



/* /////////////////////////////////// */
/*MOFICICAR PARA DAR ESTILO*/
.MuiInputLabel-formControl{  
  top:0;
  left: 0;
  position: absolute;
  transform: none !important;
  color:#FFF !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px !important;
  padding-bottom: 10px !important;
}


.MuiInput-input{
  /* fff8 */
  color: #fff !important;
   font-family: 'Roboto', sans-serif !important;
    border-bottom: #FFF !important;
    font-size: 18px !important;
    padding-top: 10px;
  }
  .MuiInput-input option { color: #000; } 
  .MuiInput-underline{
    padding-top: 10px;
  }


  a.active{
    background-color: #000000 !important;
    color: #FFF !important;
    border-color: #000000 !important;    
  }

  .mensaje{
    color: #FFF !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 40px !important;
  }
  .msgbienvenida {
    color: #FFF;
  }

  .terminos{
    color: #FFF !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 22px !important;
  }
  .hterminos{
    color: #FFF !important;
  }

  .error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .error-input {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

button:focus{
  outline: 0 !important;
}

  

  
/* 

  .MuiInput-underline:before{
    border-bottom: 1px solid #FFF !important;
    color: #000000 !important;
    
  }
  .MuiInput-underline::after{  
    border-bottom: 1px solid #fff8 !important;        
  }
  a.active{
    background-color: #000000 !important;
    color: #FFF !important;
    border-color: #000000 !important;    
  }
  .MuiTypography-body1{
    color: #FFF !important;
    font-family: 'Oswald', sans-serif !important;
    font-size: 22px !important;
  }
  .Mui-checked{
    color: #FFF !important;
  } */