.contenedor-modal{
    padding: 20px !important;
}

.modal-content{
    background-color: white;
    color: black;
    border: none !important;
    overflow-y: auto !important;
    max-height: 70%;
    padding: 20px;
    .title{
        font-size: 26px;
    }
    ol{
        list-style-type:decimal;                        
        li::marker{                    
            font-weight: bold;             
                    
        }

    }
}


