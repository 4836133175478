@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);

/* @import url("https://fonts.googleapis.com/css?family=Roboto"); */

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  margin:0;
  padding:0;  
  color: #fff;
  font-family: 'Roboto', sans-serif !important;
  font-size: 22px !important; 
  background-image: linear-gradient(to left, #5348E2, #22DEEA);  
  background-size: cover;
  background-repeat: no-repeat;

  min-height: 100vh;
}


.header{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.logo {
  width: 180px;  
  height: 80px;
  margin-left: 3rem;
  /* style={{ height: '70px', width: '180px' }} */
}

.iconoscadem{
  margin-right: 3rem;
  display: flex;  
  align-items: center;
  flex-wrap: nowrap;
}

.formClass{
  /* z-index: 1; */
  padding-bottom: 30px;
}

.form-group{
  margin-bottom: 0.6rem !important;
}
.title_container{
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boton{
  background-color: #7c3088 ;
  margin-left: 10px ;
  color: #FFF ;  
}

.nav-tabs{
border-bottom: none !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{  
  
  color: #fff !important;
  /* 5348E2 */
  background-image: linear-gradient(to left, #5348E2, #80DEEA) !important;  
  /* border-color: #7c3088 !important */
  border:none !important;     
}



.MuiInputBase-input {
  color:#FFF;
}


.MuiFormLabel-root{
  color: white !important;
}

.MuiInput-underline-24:hover:not(.MuiInput-disabled-23):not(.MuiInput-focused-22):not(.MuiInput-error-25):before {
  border-bottom: 2px solid rgb(255, 255, 255) !important;
}



/* /////////////////////////////////// */
/*MOFICICAR PARA DAR ESTILO*/
.MuiInputLabel-formControl{  
  top:0;
  left: 0;
  position: absolute;
  transform: none !important;
  color:#FFF !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px !important;
  padding-bottom: 10px !important;
}


.MuiInput-input{
  /* fff8 */
  color: #fff !important;
   font-family: 'Roboto', sans-serif !important;
    border-bottom: #FFF !important;
    font-size: 18px !important;
    padding-top: 10px;
  }
  .MuiInput-input option { color: #000; } 
  .MuiInput-underline{
    padding-top: 10px;
  }


  a.active{
    background-color: #000000 !important;
    color: #FFF !important;
    border-color: #000000 !important;    
  }

  .mensaje{
    color: #FFF !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 40px !important;
  }
  .msgbienvenida {
    color: #FFF;
  }

  .terminos{
    color: #FFF !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 22px !important;
  }
  .hterminos{
    color: #FFF !important;
  }

  .error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .error-input {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

button:focus{
  outline: 0 !important;
}

  

  
/* 

  .MuiInput-underline:before{
    border-bottom: 1px solid #FFF !important;
    color: #000000 !important;
    
  }
  .MuiInput-underline::after{  
    border-bottom: 1px solid #fff8 !important;        
  }
  a.active{
    background-color: #000000 !important;
    color: #FFF !important;
    border-color: #000000 !important;    
  }
  .MuiTypography-body1{
    color: #FFF !important;
    font-family: 'Oswald', sans-serif !important;
    font-size: 22px !important;
  }
  .Mui-checked{
    color: #FFF !important;
  } */
.contenedor-modal {
  padding: 20px !important; }

.modal-content {
  background-color: white;
  color: black;
  border: none !important;
  overflow-y: auto !important;
  max-height: 70%;
  padding: 20px; }
  .modal-content .title {
    font-size: 26px; }
  .modal-content ol {
    list-style-type: decimal; }
    .modal-content ol li::marker {
      font-weight: bold; }

@font-face {
  font-family: 'StolziTitle';
  src: local('Stolzl-Medium'), url(/static/media/Stolzl-Medium.b524de23.ttf) format('truetype');
}

@font-face {
  font-family: 'StolziContent';
  src: local('Stolzl-Book'), url(/static/media/Stolzl-Book.077c9183.ttf) format('truetype');
}

.titulo-agradecimiento {
  font-size: 32px;
  font-family: 'StolziTitle';
}

.contenido-agradecimiento {
  font-family: 'StolziContent';
}

.separado {
  letter-spacing: 1px; 
  font-size: 24px;
}

.tab {
  padding:0 !important;
   background-color: rgba(0,0,0,0.3);
   display: node !important;
  /*
   color: rgba(255,255,255,0.3);
  pointer-events: none;     */
}
.nav-tabs .nav-link{
  border:none !important;
  border-radius: 0 !important;
  /* border-top-left-radius: 0 !important;
  border-top-left-radius: 0 !important; */
}

/* .tab {
  padding:0 !important;
  background-color: rgba(0,0,0,0.3);
  color: rgba(255,255,255,0.3);
  pointer-events: none;  
  border-bottom:none !important; 
} */


