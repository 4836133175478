
/* @import url("https://fonts.googleapis.com/css?family=Roboto"); */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  margin:0;
  padding:0;  
  color: #fff;
  font-family: 'Roboto', sans-serif !important;
  font-size: 22px !important; 
  background-image: linear-gradient(to left, #5348E2, #22DEEA);  
  background-size: cover;
  background-repeat: no-repeat;

  min-height: 100vh;
}

