@font-face {
  font-family: 'StolziTitle';
  src: local('Stolzl-Medium'), url(./../../fonts/Stolzl-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'StolziContent';
  src: local('Stolzl-Book'), url(./../../fonts/Stolzl-Book.ttf) format('truetype');
}

.titulo-agradecimiento {
  font-size: 32px;
  font-family: 'StolziTitle';
}

.contenido-agradecimiento {
  font-family: 'StolziContent';
}

.separado {
  letter-spacing: 1px; 
  font-size: 24px;
}